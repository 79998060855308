import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const moduleRoutes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    component: () => import('@/views/index/index'),
    name: 'index',
    meta: {
      title: '首页',
      requireAuth: true,
    },
  }, {
    path: '/about',
    component: () => import('@/views/about/index'),
    name: 'about',
    meta: {
      title: 'about',
      requireAuth: true,
    },
  }, {
    path: '/demo',
    component: () => import('@/views/demo/index'),
    name: 'demo',
    meta: {
      title: 'demo',
      requireAuth: true,
    },
  }, {
    path: '/contact',
    component: () => import('@/views/contact/index'),
    name: 'contact',
    meta: {
      title: 'contact',
      requireAuth: true,
    },
  }, {
    path: '/Privacy-policy',
    component: () => import('@/views/Privacy-policy/index'),
    name: 'Privacy-policy',
    meta: {
      title: 'Privacy-policy',
      requireAuth: true,
    },
  },
  {
    path: '/termsAndConditions',
    component: () => import('@/views/termsAndConditions/index'),
    name: 'termsAndConditions',
    meta: {
      title: 'termsAndConditions',
      requireAuth: true,
    },
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: moduleRoutes,
})

export default router
