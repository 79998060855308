<template>
  <div class="footer">
    <div class="footer-footer" v-show="footerShow">
      <div class="left">
        <div class="left-title">Ready to get started?</div>
        <div class="left-desc">Let's get in touch.</div>
        <div class="left-btn" @click="$router.push('/demo')">
          <img alt="" src="../assets/Group 74.png" />
        </div>
      </div>
      <div class="right">
        <iframe
          id="iframeMain"
          src="https://openc.pro/widget-page/?widgetId=UFUzQmY2UHY"
          style="width: 100%; border-radius: 20px"
          frameborder="no"
          border="0"
          height="100%"
        ></iframe>
        <!-- <el-tabs type="border-card" @tab-click="getCurrentIndex">
          <el-tab-pane>
            <div slot="label" class="height:100%;text-align:center">
              <span class="tabs-title">Buy</span><br /><span class="tabs-desc"
                >Crypto</span
              >
            </div>
            <div class="content">
              <el-form :model="form" sty>
                <el-form-item>
                  <el-select v-model="form.region" placeholder="Australia">
                    <el-option label="Australia" value="Australia"></el-option>
                    <el-option label="Australia" value="beijing"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div class="method">
                <div class="method-title">Payment Method</div>
                <div class="method-box">
                  <div class="method-point"></div>
                  PayID
                </div>
              </div>
              <div class="roughly">
                <div class="roughly-title">1BTC is roughly</div>
                <div class="roughly-box">
                  <div class="roughly-left">
                    <div class="roughly-position">
                      <div class="roughly-left-top">You pay</div>
                      <div class="roughly-left-btm">1000</div>
                    </div>
                  </div>
                  <div class="roughly-right">
                    <div class="roughly-right-img">
                      <img alt="" src="../assets/USD.png" />
                    </div>
                    <div class="roughly-right-desc">USD</div>
                  </div>
                </div>
              </div>
              <div class="icon">
                <img alt="" src="../assets/Group 82.png" />
              </div>
              <div class="roughly">
                <div class="roughly-box">
                  <div class="roughly-left">
                    <div class="roughly-position">
                      <div class="roughly-left-top">You pay</div>
                      <div class="roughly-left-btm">1000</div>
                    </div>
                  </div>
                  <div class="roughly-right">
                    <div class="roughly-right-img">
                      <img alt="" src="../assets/USDT.webp" />
                    </div>
                    <div class="roughly-right-desc">USDT</div>
                  </div>
                </div>
                <div class="button" @click="goPages">Confirm</div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <div slot="label" class="height:100%;text-align:center">
              <span class="tabs-title">Sell</span><br /><span class="tabs-desc"
                >Crypto</span
              >
            </div>
            Sell
          </el-tab-pane>
        </el-tabs> -->
      </div>
    </div>
    <div class="footer-btm flex-x flex-alc flex-btn">
      <div class="footer-btm-img">
        <img alt="" src="../assets/Group 22.svg" />
      </div>
      <div class="footer-btm-desc footer-btm-desc-hover1" @click="$router.push('/demo')">
        Ready to get started
      </div>
      <div class="footer-btm-desc footer-btm-desc-hover2" @click="$router.push('/about')">About</div>
      <div class="footer-btm-desc footer-btm-desc-hover3" @click="$router.push('/Privacy-policy')">
        Privacy policy
      </div>
	  <div class="footer-btm-desc footer-btm-desc-hover3" @click="$router.push('/termsAndConditions')">
	   Terms And Conditions
	  </div>
      <div class="footer-btm-desc footer-btm-desc-hover4" @click="goWidget">Widget</div>
	  <div class="footer-btm-desc footer-btm-desc-hover5"  @click="goHelp">Help Center</div>
      <div class="iconlogo">
        <div class="facebook" >
          <img alt="" src="../assets/ion-social-facebook - Ionicons.png" />
        </div>
        <div class="twitter" @click="goTwitter">
          <img alt="" src="../assets/twitter - FontAwesome.png" />
        </div>
        <div class="email" @click="goYoutube">
          <img alt="" src="../assets/youtube-play - FontAwesome.png" />
        </div>
      </div>
    </div>
    <div class="footer-title">
      <!-- Created by Geovani Almeida CC 2018, Creative Commons 4.0 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "footerPage",
  data() {
    return {
      form: {},
      currenIndex: 0,
	  footerShow: true,
    };
  },
  created() {},
  async mounted() {},
  watch: {
	$route(to,form){
		if(to.fullPath == '/termsAndConditions'||to.fullPath == '/Privacy-policy'){
			this.footerShow = false
		}else{
			this.footerShow = true
		}
	} 
  },
  methods: {
    getCurrentIndex(val) {
      console.log(val.index);
      this.currenIndex = val.index;
    },
    goPages() {
      window.open("https://demo.openc.pro/");
    },
	goHelp(){
		let open = window.open('','_blank')
		open.location='https://openc.zendesk.com'
		//window.open("https://openc.zendesk.com");
	},
	goTwitter(){
		let open = window.open('','_blank')
		open.location='https://twitter.com/Open_Cryptos'
	},
	goYoutube(){
		let open = window.open('','_blank')
		open.location='https://www.youtube.com/@opencsolutions'
	},
	goWidget(){
		let open = window.open('','_blank')
		open.location='https://openc.pro/widget-page/?widgetId=UFUzQmY2UHY'
		//window.open("https://openc.zendesk.com");
	},
	
  },
};
</script>

<style lang="scss" scoped>
@import "../css/public.scss";

.left {
  &-title {
    font-size: 56px;
    color: #ffffff;
  }

  &-desc {
    font-size: 24px;
    color: #ffffff;
    opacity: 0.5;
    font-family: fontFace1 !important;
    margin-top: 10px;
  }

  &-btn {
    width: 273px;
    height: 80px;
    margin-top: 130px;
    cursor: pointer;
  }
}

.footer {
  background: #3fbac2;

  &-title {
    font-size: 18px;
    color: #ffffff;
    opacity: 0.6;
    text-align: center;
    padding-top: 87px;
    padding-bottom: 153px;
    box-sizing: border-box;
  }

  &-btm {
    width: 1480px;
    padding-top: 75px;
    box-sizing: border-box;
    border-top: 1px solid #fff;
    margin: 0 auto;
    display: flex;

    &-img {
      width: 160px;
      height: 80px;
    }

    &-desc {
      font-size: 18px;
      color: #ffffff;
      font-family: fontFace1 !important;
      cursor: pointer;
	  &-hover1:hover {
		 font-size: 20px;
	  }
	  &-hover2:hover {
	  		 font-size: 20px;
	  }
	  &-hover3:hover {
	 		 font-size: 20px;
	 }
	  &-hover4:hover {
	 		 font-size: 20px;
	 }
	  &-hover5:hover {
	 		 font-size: 20px;
	 }
    }
  }

  .iconlogo {
    display: flex;

    div {
      margin-left: 56px;
    }

    .facebook {
      width: 10px;
      height: 21px;
	  &:hover {
		  width: 12px;
		  height: 23px;
	  }
    }

    .twitter {
      width: 23px;
      height: 20px;
	  &:hover {
	  		  width: 25px;
	  		  height: 22px;
	  }
    }

    .email {
      width: 24px;
      height: 18px;
	  &:hover {
	  		  width: 26px;
	  		  height: 20px;
	  }
    }
  }
}

.footer-footer {
  display: flex;
  justify-content: space-between;
  padding: 122px 220px;
  box-sizing: border-box;
}

.right {
  width: 508px;
  background: #ffffff;
  border-radius: 18px;
  height: 800px;
  .tabs-title {
    font-size: 24px;
    color: #134c50;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
  }

  ::v-deep .el-tabs__item {
    width: 50%;
    height: 100%;
  }

  ::v-deep .el-tabs__nav {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  ::v-deep .tabs-desc {
    font-size: 12px;
    color: #000000;
    opacity: 0.4;
    display: inline-block;
    width: 100%;
    text-align: center;
    font-family: fontFace1 !important;
  }

  ::v-deep .el-tabs--border-card > .el-tabs__header {
    background: #f3fbfb;
  }

  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #000;
  }

  ::v-deep .el-select {
    width: 100%;
    font-family: fontFace;
    border-radius: 12px;
  }

  ::v-deep .el-input__inner {
    font-family: fontFace;
  }
}

.content {
  padding: 30px 40px;
  box-sizing: border-box;
}

::v-deep .el-tabs {
  background: #ffffff;
  border-radius: 18px;
  border: none;
}

.method {
  &-title {
    font-size: 18px;
    margin-top: 36px;
    margin-bottom: 10px;
  }

  &-point {
    width: 6px;
    height: 6px;
    background: #3fbac2;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  &-box {
    position: relative;
    width: 230px;
    height: 58px;
    line-height: 58px;
    text-align: center;
    background: rgba(63, 186, 194, 0.06);
    border: 1px solid #3fbac2;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 24px;
    color: #134c50;
  }
}

.roughly {
  &-title {
    font-size: 16px;
    color: #000000;
    opacity: 0.4;
    padding-top: 37px;
    padding-bottom: 17px;
  }

  &-box {
    width: 528px;
    height: 80.92px;
    background: rgba(63, 186, 194, 0.06);
    border: 1px solid #3fbac2;
    box-sizing: border-box;
    border-radius: 12px;
    padding-left: 20px;
    display: flex;
  }

  &-left {
    width: 356px;
    height: 100%;
    border-right: 1px solid #3fbac2;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &-top {
      font-size: 16px;
      color: #134c50;
      opacity: 0.7;
      font-family: fontFace1 !important;
    }

    &-btm {
      font-size: 24px;
      color: #134c50;
    }
  }

  &-position {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &-right {
    width: 136px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-img {
      width: 28px;
      height: 28px;
    }

    &-desc {
      font-size: 18px;
      color: #134c50;
      margin-left: 16px;
    }
  }
}

.icon {
  width: 38px;
  height: 75px;
}

.button {
  width: 528px;
  height: 73.56px;
  background: #3fbac2;
  border-radius: 12px;
  margin: 35px auto 0;
  font-size: 24px;
  /* or 32px */
  text-align: center;
  line-height: 73.56px;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-device-width: 768px) {
  .footer-footer {
    padding: 122px 100px;
    box-sizing: border-box;
    display: block;
  }
  .left {
    text-align: center;
    &-title {
      font-size: 90px;
    }
    &-desc {
      font-size: 60px;
    }
    &-btn {
      width: 500px;
      height: 150px;
      margin: 100px auto;
    }
  }
  .right {
    width: 100%;
    height: 100vh;
    background: #ffffff;
    border-radius: 18px;
    .tabs-title {
      font-size: 90px;
      color: #134c50;
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 50px 0;
      box-sizing: border-box;
    }

    ::v-deep .el-tabs__item {
      width: 50%;
      height: 100%;
    }

    ::v-deep .el-tabs__nav {
      width: 100%;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
    }

    ::v-deep .tabs-desc {
      font-size: 50px;
      color: #000000;
      opacity: 0.4;
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: fontFace1 !important;
    }

    ::v-deep .el-tabs--border-card > .el-tabs__header {
      background: #f3fbfb;
    }

    ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
      color: #000;
    }

    ::v-deep .el-select {
      width: 100%;
      font-family: fontFace;
      border-radius: 12px;
    }

    ::v-deep .el-input__inner {
      font-family: fontFace;
    }
  }
  .content {
    padding: 30px 40px;
    box-sizing: border-box;
  }
  ::v-deep .el-input {
    margin-top: 50px;
    input {
      height: 100px;
      font-size: 50px;
      line-height: 100px;
    }
  }
  ::v-deep .el-tabs {
    background: #ffffff;
    border-radius: 18px;
    border: none;
  }
  .method {
    &-title {
      font-size: 60px;
      margin-top: 36px;
      margin-bottom: 10px;
    }

    &-point {
      width: 20px;
      height: 20px;
      background: #3fbac2;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 60px;
    }

    &-box {
      position: relative;
      width: 460px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      background: rgba(63, 186, 194, 0.06);
      border: 1px solid #3fbac2;
      box-sizing: border-box;
      border-radius: 12px;
      font-size: 50px;
      color: #134c50;
      margin-top: 50px;
    }
  }
  .roughly {
    width: 100%;
    &-title {
      font-size: 50px;
      color: #000000;
      opacity: 0.4;
      padding-top: 37px;
      padding-bottom: 17px;
    }

    &-box {
      width: 100%;
      height: 150px;
      background: rgba(63, 186, 194, 0.06);
      border: 1px solid #3fbac2;
      box-sizing: border-box;
      border-radius: 12px;
      padding-left: 20px;
      display: flex;
    }

    &-left {
      width: 80%;
      height: 100%;
      border-right: 1px solid #3fbac2;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &-top {
        font-size: 50px;
        color: #134c50;
        opacity: 0.7;
        font-family: fontFace1 !important;
      }

      &-btm {
        font-size: 24px;
        color: #134c50;
      }
    }

    &-position {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    &-right {
      width: 20%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &-img {
        width: 70px;
        height: 70px;
      }

      &-desc {
        font-size: 50px;
        color: #134c50;
        margin-left: 16px;
      }
    }
  }

  .icon {
    width: 38 * 3px;
    height: 75 * 3px;
  }

  .button {
    width: 528 * 3px;
    height: 73.56 * 3px;
    background: #3fbac2;
    border-radius: 12 * 3px;
    margin: 35 * 3px auto 0;
    font-size: 24 * 3px;
    /* or 32px */
    text-align: center;
    line-height: 73.56 * 3px;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 40px;
  }
  .footer {
    background: #3fbac2;

    &-title {
      font-size: 18px;
      color: #ffffff;
      opacity: 0.6;
      text-align: center;
      padding-top: 87px;
      padding-bottom: 153px;
      box-sizing: border-box;
    }

    &-btm {
      width: 1480px;
      padding-top: 75px;
      box-sizing: border-box;
      border-top: 1px solid #fff;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      &-img {
        width: 100%;
        margin-bottom: 120px;
        height: 100%;
        img {
          width: 160 * 2px;
          height: 60 * 2px;
        }
      }

      &-desc {
        font-size: 60px;
        color: #ffffff;
        font-family: fontFace1 !important;
        width: 50%;
        margin: 30px 0;
      }
    }

    .iconlogo {
      display: flex;
      width: 100%;
      justify-content: center;
      margin: 200px 0;
      div {
        margin-left: 300px;
      }
      .facebook {
        width: 10 * 4px;
        height: 21 * 4px;
        margin-left: 0;
      }

      .twitter {
        width: 23 * 4px;
        height: 20 * 4px;
      }

      .email {
        width: 24 * 4px;
        height: 18 * 4px;
      }
    }
  }
}
</style>
