<template>
  <div id="app">
    <headerNav></headerNav>
    <router-view />
    <footer-page></footer-page>
  </div>
</template>

<script>
// import {MOBILE} from '@/utils/hardcode'

export default {
  name: "App",
  components: {},

  data() {
    return {
      linkPath: "",
    };
  },
  mounted() {},
  methods: {},
  created() {},
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /*list-style: none;*/
  text-decoration: none;
  /*font-family: "Verdana, Geneva, Arial, Helvetica, sans-serif";*/
}
#app {
  position: relative;
  top: 0;
}
html,
body {
  background: #fff;
  overflow-x: hidden;
  font-family: "fontFace";
}

img {
  width: 100%;
  height: 100%;
  display: block;
}

@font-face {
  font-family: "fontFace";
  src: url("./font/Montserrat-Medium-7.otf");
}

@font-face {
  font-family: "fontFace1";
  src: url("./font/Montserrat-Light-6.otf");
}
</style>
