<template>
  <div>
    <div :style="style" class="header flex-x flex-btn relative font-medium">
      <div class="left flex-x flex-alc">
        <div class="logo">
          <img alt="" src="../assets/Group 21.svg" />
        </div>
        <div
          :style="{ color: $route.path == '/index' ? '#3FBAC2' : '#000' }"
          class="header_title header_first header_lineHeight"
          @click="goPage('/index')"
        >
          {{ $t("home") }}
        </div>

        <div class="header_title ml-100 header_hover relative">
          <div
            :style="{ color: $route.path == '/about' ? '#3FBAC2' : '#000' }"
            class="flex-x flex-alc header_lineHeight"
            @click="goPage('/about')"
          >
            {{ $t("About") }}
            <!-- <img
              :src="
                $route.path == '/about'
                  ? require('@/assets/Rectangle 4.png')
                  : require('@/assets/Rectangle 3.png')
              "
              alt=""
              class="ml-10"
            /> -->
          </div>
          <!-- <ul class="absolute ul1">
            <li
              :style="{
                color: $route.path == '/introduce' ? '#3FBAC2' : '#000',
              }"
              class="li_hover"
              @click="goPage('/introduce')"
            >
              {{ $t("About team") }}
            </li>
            <li
              :style="{
                color: $route.path == '/management' ? '#3FBAC2' : '#000',
              }"
              class="mt-28 li_hover"
              @click="goPage('/management')"
            >
              {{ $t("Product") }}
            </li>
            <li
              :style="{ color: $route.path == '/culture' ? '#3FBAC2' : '#000' }"
              class="mt-28 li_hover"
              @click="goPage('/culture')"
            >
              {{ $t("Cooperative Partner") }}
            </li>
          </ul> -->
        </div>
        <!-- <div class="header_title ml-100 header_hover relative">
          <div class="flex-x flex-alc header_lineHeight">{{ $t('Serve') }}
          </div>
        </div> -->
        <div
          :style="{ color: $route.path == '/contact' ? '#3FBAC2' : '#000' }"
          class="header_title ml-100 header_lineHeight"
          @click="goPage('/contact')"
        >
          {{ $t("Contact") }}
        </div>
        <div
          :style="{ color: $route.path == '/demo' ? '#3FBAC2' : '#000' }"
          class="header_title ml-100 header_lineHeight"
          @click="goWidget()"
        >
          Widget
        </div>
		<div
		  :style="{ color : '#000' }"
		  class="header_title ml-100 header_lineHeight"
		  @click="goHelp"
		>
		  Help Center
		</div>
        <!-- <div
          :style="{
            color: $route.path == '/Privacy-policy' ? '#3FBAC2' : '#000',
          }"
          class="header_title ml-100 header_lineHeight"
          @click="goPage('/Privacy-policy')"
        >
          Privacy policy
        </div> -->
      </div>
      <div class="right header_title mr-100 header_hover relative">
        <div class="flex-x flex-alc header_lineHeight">
          <!-- {{ $t("English")
          }}<img
            alt=""
            class="ml-10"
            mode="widthFix"
            src="../assets/Rectangle 3.png"
          /> -->
        </div>
        <!-- <ul class="absolute ul1">
          <li
            :style="{ color: $route.path == '/introduce' ? '#3FBAC2' : '#000' }"
            class="li_hover"
            @click="goPage('/introduce')"
          >
            {{ $t("zh") }}
          </li>
          <li
            :style="{ color: $route.path == '/introduce' ? '#3FBAC2' : '#000' }"
            class="li_hover"
            @click="goPage('/introduce')"
          >
            {{ $t("English") }}
          </li>
        </ul> -->
      </div>
      <div
        class="menu header_hover relative"
        @click="showMenu = true"
        v-if="!showMenu"
      >
        <img alt="" src="../assets/Group 193.png" />
      </div>
      <div
        class="menu header_hover relative"
        @click="showMenu = false"
        v-if="showMenu"
      >
        <img alt="" src="../assets/Group 194.png" />
      </div>
      <div class="absolute ul1 ulMenu" v-show="showMenu">
        <div
          :style="{
            color: $route.path == '/index' ? '#3FBAC2' : '#000',
          }"
          class="li_hover"
          @click="goPage('/index')"
        >
          {{ $t("Home") }}
        </div>
        <div
          :style="{
            color: $route.path == '/about' ? '#3FBAC2' : '#000',
          }"
          class="mt-28 li_hover"
          @click="goPage('/about')"
        >
          {{ $t("about") }}
        </div>
        <div
          :style="{ color: $route.path == '/contact' ? '#3FBAC2' : '#000' }"
          class="mt-28 li_hover"
          @click="goPage('/contact')"
        >
          {{ $t("contact") }}
        </div>
        <div
          :style="{ color: $route.path == '/demo' ? '#3FBAC2' : '#000' }"
          class="mt-28 li_hover"
          @click="goPage('/demo')"
        >
          {{ $t("demo") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerNav",
  data() {
    return {
      logoShow: true,
      activeIndex: "1",
      style: {},
      opacity: 0,
      showMenu: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
  },
  methods: {
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.opacity =
        Math.abs(Math.round(scrollTop)) / 250 > 0.8
          ? 0.8
          : Math.abs(Math.round(scrollTop)) / 250;
      this.style = { background: `rgba(255,255,255,${this.opacity})` };
    },
    goPage(val) {
      console.log(val);
      if (val == this.$route.path) {
        return;
      }
      if (val == "/demo") {
        this.logoShow = false;
      } else {
        this.logoShow = true;
      }
      this.$router.push(val);
      this.showMenu = false;
    },
    changeLanguage(val) {
      localStorage.setItem("language", val);
      this.$router.go(0);
    },
    goPages() {
      window.open("https://demo.openc.pro/");
    },
	goHelp(){
		let open = window.open('','_blank')
		open.location='https://openc.zendesk.com'
		//window.open("https://openc.zendesk.com");
	},
	goWidget(){
		let open = window.open('','_blank')
		open.location='https://openc.pro/widget-page/?widgetId=UFUzQmY2UHY'
		//window.open("https://openc.zendesk.com");
	},
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
  },
};
</script>
<style lang="scss" scoped>
@import "../css/public.scss";

* {
  list-style: none;
}

.logo {
  width: 170px;
  height: 80px;
  margin-left: 220px;
  //margin-top: 48px;
}

.header {
  //height: 90px;
  position: fixed;
  width: 100%;
  z-index: 999;

  &_title {
    @include fs(20px, #000, 500);
    cursor: pointer;
    min-width: 35px;
    white-space: nowrap;

    img {
      width: 7px;
      height: 7px;
    }
  }

  ul {
    display: none;
    //height: 230px;
    background: #ffffff;
    box-shadow: 0px 0px 18px rgba(208, 223, 224, 0.85);
    border-radius: 12px;
    opacity: 0.9183;
    text-align: center;
    padding: 22px 26px;
    box-sizing: border-box;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }

  &_first {
    margin-left: 176px;
  }

  &_hover:hover .ul1 {
    display: block;
    animation: animated 0.5s ease;
  }

  &_hover:hover .ul2 {
    display: block;
    animation: animated1 0.5s ease;
    height: 115px;
  }

  &_hover:hover img {
    transform: rotate(180deg);
  }

  @keyframes rotateTr {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  @keyframes animated {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      //height: 230px;
      opacity: 0.9183;
    }
  }
  @keyframes animated1 {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 115px;
      opacity: 0.9183;
    }
  }

  .right {
    &_switch {
      @include fs(18px, #000, 500);
      cursor: pointer;
    }

    &_line {
      width: 1px;
      height: 25px;
      background: #ffffff;
    }

    &_last {
      margin-right: 162px;
    }
  }
}

.header_lineHeight {
  line-height: 90px;
}

.li_hover:hover {
  color: #3fbac2 !important;
}
.menu {
  display: none;
}
@media screen and (max-device-width: 768px) {
  .header_lineHeight {
    display: none !important;
  }
  .logo {
    width: 320px;
    height: 180px;
    margin-top: 40px;
    margin-left: 100px;
  }
  .menu {
    display: block;
    width: 180px;
    height: 180px;
    margin-top: 40px;
    margin-right: 100px;
  }
  .ulMenu {
    position: absolute;
    width: 90%;
    background: #ffffff;
    box-shadow: 0px 0px 29.8365px #f0f0f0;
    border-radius: 30px;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .li_hover {
      height: 200px;
      line-height: 200px;
      box-sizing: border-box;
    }
  }
  .ulMenu {
    animation: animatedMenu 0.5s ease;
  }
  @keyframes animatedMenu {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 800px;
      opacity: 0.9183;
    }
  }
}
</style>
