import Vue from 'vue'
import App from './App.vue'
//路由
import router from './router/index'
router.afterEach(()=>{
  window.scrollTo(0,0)
})
Vue.config.productionTip = false
import './css/public.scss';
//中英
import i18n from './language/index'
//页面适配
import './utils/flexible.js'
// import './utils/config.js'
import 'element-ui/lib/theme-chalk/index.css';
//引用公用组件
import Components from '@/utils/components';
import ElementUI from 'element-ui';
//图片地址
Vue.prototype.$photoUrl=process.env.VUE_APP_PHOTO_URL_STAGE
Vue.use(ElementUI);
import animated from 'animate.css'
Vue.use(animated)
import 'swiper/css/swiper.css';
import moment from 'moment';
Vue.prototype.$moment = moment;
Vue.use(Components);
import vueSwiper from 'vue-awesome-swiper'
Vue.use(vueSwiper)
new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
